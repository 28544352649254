import { CabButton } from "@CabComponents/CabButton";
import { CabModal } from "@CabComponents/CabModal";
import { CabTextInput } from "@CabComponents/CabTextInput";
import { Box, FormControl, FormLabel } from "@mui/material";
import { ReactElement } from "react";
import { Controller, useForm } from "react-hook-form";
import { companyRegex } from "../../constants";
import { Company, CompanyCategory } from "../../store/cabinetApi/generated/crm";
import colors from "../../colors";
import { CabDropdown } from "@CabComponents/CabDropdown";
import { CabToggleChip } from "@CabComponents/CabToggleChip";



interface NewOrganizationModalProps {
  open: boolean;
  onClose: () => void;
  organizationCategories: CompanyCategory[];
  onCreateCompany: (organization: Company) => Promise<void>;
  isCreatingContactOrg: boolean;
}

interface FormInput {
  name: string;
  emailDomain: string;
  notes: string;
  category: number;
}

const NewOrganizationModal = ({open, onClose, organizationCategories, onCreateCompany, isCreatingContactOrg}
: NewOrganizationModalProps): ReactElement => {

  const defaultValues: FormInput = {
    name: '',
    emailDomain: '',
    notes: '',
    category: -1
  };

  const contactForm = useForm<FormInput>({
    defaultValues: defaultValues
  });
  const { control, reset, handleSubmit: formSubmit, formState: { isValid } } = contactForm;

  const categoryOptions = [{id: -1, name: "None", color: colors.white800}, ...organizationCategories];

  const handleSubmit = async (v: FormInput) => {
    await onCreateCompany(
      {
        id: -1,
        name: v.name,
        email_domain: v.emailDomain,
        notes: v.notes || null,
        category: v.category && v.category > 0 ? v.category : undefined,
      }
    );
    reset();
    onClose();
  };

  return (
    <CabModal
      open={open}
      onClose={onClose}
      title={'Create New Contact Organization'}
      closeIcon
      actionButtons={
        <Box display='flex' justifyContent={'space-between'} width='100%'>
          <CabButton color='error' onClick={() => reset()}>
            Discard
          </CabButton>
          <Box display='flex' gap={1}>
            <CabButton buttonType="secondary" onClick={onClose}>
              Cancel
            </CabButton>
            <CabButton 
              disabled={!isValid || isCreatingContactOrg} 
              onClick={formSubmit(handleSubmit)}
            >
              Save
            </CabButton>
          </Box>
        </Box>
      }
    >
      <Box display='flex' flexDirection='column' gap={1} width={'100%'}>
        <FormControl>
          <FormLabel>Name*</FormLabel>
          <Controller name='name' control={control}
            rules={{ required: true }}
            render={({ field: { ref, ...field } }) => (
              <CabTextInput
                {...field}
                inputRef={ref}
                required
              />
            )}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Email Domain*</FormLabel>
          <Controller 
            name='emailDomain' control={control}
            rules={{ required: true, pattern: companyRegex }}
            render={({ field: { ref, ...field } }) => (
              <CabTextInput
                {...field}
                type='email'
                inputRef={ref}
                required
              />
            )}/>
        </FormControl>
        <FormControl>
          <FormLabel>Category</FormLabel>
          <Controller name='category' control={control}
            render={({ field: { onChange, value } }) => (
              <CabDropdown<number>
                value={value || -1}
                onChange={onChange}
                options={categoryOptions.map(option => ({
                  value: option.id,
                  label: <CabToggleChip chipColor={option.color || colors.white800} label={option.name} 
                    selected={true}/>
                }))}
              />
            )}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Notes</FormLabel>
          <Controller name='notes' control={control}
            render={({ field: { ref, ...field } }) => (
              <CabTextInput
                {...field}
                multiline
                minRows={2}
                maxRows={4}
                inputRef={ref}
              />
            )}
          />
        </FormControl>
      </Box>
    </CabModal>
  );
};

export default NewOrganizationModal;